import CoreApi from '@/api/core'
import RedirectFilterServiceVlm from '@/services/redirect/RedirectFilterServiceVlm'
import { createCrudStore } from '@/store/modules/simpleCrudStore'

const filterModel = {
  siteId: '',
  isAutoGenerated: null,
  originalUrl: '',
  alias: '',
  validFrom: ''
}
const store = createCrudStore({
  apiName: '/redirect',
  filterModel,
  apiInstanceCallback: () => CoreApi(),
  buildFilterQueryCallback: RedirectFilterServiceVlm.buildFilterQuery
})

export default store
